.solution-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 30px;
  flex-wrap: wrap;
}

.solution-card-container img {
  width: 500px;
}

.payment-header {
  font-family: Playfair Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 37.32px;
  text-align: center;
}

.payment-desc {
  font-family: Glory;
  font-size: 18px;
  font-weight: 500;
  line-height: 20.16px;
  text-align: center;
  /* padding: 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 871px;
  margin: auto;
}

.payment-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  gap: 30px;
  flex-wrap: wrap;
}

.payment-benefits-container {
  background-image: url("../../assets/app-dev-packages.webp");
  background-size: cover;
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 110px;
  align-items: center;
}

.payment-card-container {
  max-width: 360px;
  height: 232px;
  padding: 20px;
  border: 1px solid #00bce8;
  border-radius: 15px;
}

.payment-card-container img {
  width: 46px;
  height: 46px;
}

.payment-card-header {
  font-family: Playfair Display;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: justified;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.payment-card-desc {
  font-family: Glory;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: justified;
  color: white;
}
