@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.about-us-header {
  background-image: url("../../assets/AboutUsHeader.webp");
  min-height: 722px;
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  color: white;
  font-family: "Glory", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.about-card {
  width: 280px;
  height: 322px;
  flex-shrink: 0;
  border-radius: 12px;
  background-image: url("../../assets/aboutIcons/Frame.webp");
  color: white;
}
.about-us-info {
  padding-left: 70px;
  width: 100%;
}

.about-us-info h3 {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 700;
  padding-top: 80px;
}

.header-info {
  font-family: "Glory", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #00bce8;
  margin-bottom: 30px;
}

.about-us-detail {
  font-family: "Glory", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
  overflow-y: hidden;
  line-height: 20px;
  margin-bottom: 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .about-us-info {
    padding-left: 50px;
    max-width: 600px;
  }

  .about-us-info h3 {
    font-size: 26px;
    padding-top: 60px;
  }

  .header-info,
  .about-us-detail {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .about-us-header {
    align-items: center;
    text-align: center;
  }

  .about-us-info {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }

  .about-us-info h3 {
    font-size: 24px;
    padding-top: 40px;
  }

  .header-info,
  .about-us-detail {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .about-us-header {
    min-height: 500px;
  }

  .about-us-info {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
  }

  .about-us-info h3 {
    font-size: 22px;
    padding-top: 20px;
  }

  .header-info,
  .about-us-detail {
    font-size: 14px;
  }

  .about-us-detail {
    line-height: 18px;
  }
}

/* Vision */
.vision-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin: 40px;
}

.request-demo-bg {
  background-image: url("../../assets/requestDemo.webp");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: 350px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Glory", sans-serif;
}

.request-demo-bg {
  padding: 20px;
  text-align: center;
}

.request-demo-bg h3 {
  font-size: 24px;
  font-weight: 800;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}

.request-demo-bg p {
  margin-bottom: 20px;
}

.demo-input-container {
  display: flex;
  justify-content: space-between;
  min-width: 524px;
  min-height: 54px;
  background: white;
  border-radius: 30px;
  margin: 0 auto; /* Center the container */
}

.demo-input-container p {
  color: black;
  align-self: center;
  padding-left: 20px;
}

.request-demo-btn {
  min-height: 44px;
  min-width: 179px;
  border: 1px solid;
  border-radius: 30px;
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  justify-self: flex-end;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .demo-input-container {
    display: none; /* Hide the Enter Your Email div on smaller screens */
  }
}
