@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

form {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
  padding: 32px;
  align-items: flex-start;
  gap: 24px;
  height: auto;
  width: 700px;
  border-radius: 16px;
  background: rgb(25, 31, 59);
}
input {
  padding: 5px;
  border-radius: 3px;
}
.contact-us {
  background-image: url("../../assets/contactUs.jpg");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  /* height: 1295px; */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Glory", sans-serif;
  /* background-color:#00BCE8; */
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-form-item-vertical
  .ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-d2lrxs).ant-col-24.ant-form-item-label
  > label,
:where(.css-dev-only-do-not-override-d2lrxs).ant-col-xl-24.ant-form-item-label
  > label {
  color: white;
}

.form-design {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--White, var(--white, #fff));
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 20px */
  font-family: "Glory", sans-serif;
}

.contact-form-header {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 700;
  padding-top: 80px;
}

.desc {
  max-width: 725px;
  padding-top: 15px;
  color: #00bce8;
  text-align: center;
  font-size: 20px;
}

.where-to-find {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}

.where-to-find-header {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 700;
}

.where-to-find-desc {
  max-width: 610px;
  margin-bottom: 30px;
}

.where-to-find-fact {
  color: #00bce8;
}
