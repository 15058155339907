@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");
.basic-plan-card {
  width: 300px;
  border-radius: 16px;
  padding: 20px;
  padding-top: 0px;
  background: linear-gradient(to right, #0bb0d4, #004c8c);
  color: white;
  font-family: Arial, sans-serif;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.standard-plan-card {
  width: 300px;
  border-radius: 16px;
  padding-top: 0px;
  background: linear-gradient(to right, #0bb0d4, #004c8c);
  color: white;
  font-family: Arial, sans-serif;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.plan-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
  font-family: "Glory", sans-serif;
}

.plan-features h4 {
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 10px;
  text-align: center;
  opacity: 80%;
  font-family: "Outfit", sans-serif;
}

.plan-features ul {
  list-style: none;
  padding: 0;
}

.plan-features li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.plan-features li .icon {
  font-weight: bold;
  margin-right: 10px;
}

.plan-features li.disabled {
  color: #999999;
}

.plan-features li:not(.disabled) .icon {
  color: #4caf50; /* Green for available features */
}

.plan-features li.disabled .icon {
  color: #f44336; /* Red for unavailable features */
}

.clip-path-button {
  clip-path: polygon(
    10% 0,
    90% 0,
    90% 0,
    75% calc(100% - 8px),
    calc(75% - 8px) 100%,
    calc(25% + 8px) 100%,
    25% calc(100% - 8px),
    10% 0
  );
  min-width: 200px;
}
