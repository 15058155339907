@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

/* Header */

.header {
  background-image: url("../../assets/hero-section-header.webp");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: 340px;
  height: fit-content;
  color: white;
  font-family: "Glory", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0px 0px 50px 0px;
}

.header h3 {
  font-family: "Playfair Display", serif;
  font-size: 32px;
  font-weight: 700;
  color: #1cd1fc;
  max-width: 933px;
  margin-bottom: 20px;
}

.header p {
  max-width: 933px;
  font-size: 20px;
  font-family: "Glory", sans-serif;
}

@media (max-width: 768px) {
  .header {
    padding-left: 10px;
    padding-right: 10px;
    min-height: auto;
    background-size: cover;
    padding-bottom: 70px;
  }

  .header p {
    font-size: 24px;
  }

  .header h3 {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .header {
    padding-bottom: 70px;
  }
  .first-group {
    scale: 0.7;
    height: auto;
  }
  .header p {
    font-size: 20px;
  }

  .header h3 {
    font-size: 24px;
  }
}

/* Web Development Card Design */

/* Web Development Card Container Styles */

.web-development-card-container {
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 30px;
  border-radius: 5%;
  background-color: white;

  /* border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #00bce8 -12.02%, #012d67 77.71%);
  border-image-slice: 1;
  border-radius: 10%;
  margin-bottom: 15px;
  box-shadow: 5px linear-gradient(to right, #00bce8 -12.02%, #012d67 77.71%); */
}

.web-development-card-container:hover {
  background-color: #012049;
  color: white;
  transition: 1s ease-in-out;
  background-image: url("../../assets/paymentSolution/Line&Dot.webp");
  background-position: right;
  background-repeat: no-repeat;
}
.cards-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 150px;
  margin: auto;
  gap: 16px;
}

.web-development-card-container img {
  width: 64px;
  height: 64px;
}

.web-development-card-container h3 {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

.web-development-card-container p {
  font-size: 18px;
  font-family: "Glory", sans-serif;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .web-development-card-container {
    width: 100%;
    margin-right: 0;
    padding: 15px;
  }

  .web-development-card-container h3 {
    font-size: 18px;
  }

  .web-development-card-container p {
    font-size: 16px;
  }

  .cards-container {
    padding: 0 15px;
  }
}

@media (max-width: 480px) {
  .web-development-card-container {
    width: 100%;
    margin-right: 0;
    padding: 10px;
  }

  .web-development-card-container h3 {
    font-size: 16px;
  }

  .web-development-card-container p {
    font-size: 14px;
  }

  .cards-container {
    padding: 0 10px;
  }
}

/* Web Development Journey */
/* General styles */
.our-services {
  background-image: url("../../assets/ourServices/our_service_bg.webp");
  padding: 20px;
  min-height: 736px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.website-development img {
  margin: auto;
  max-width: 550px;
}

.first-group {
  color: white;
  height: 280px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fact {
  display: flex;
  width: 380px;
  height: 52px;
  border: 1px solid;
  align-items: center;
  border-radius: 15px;
  font-size: 20px;
  font-family: "Glory", sans-serif;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  background: var(--ThemeColor_One, #00bce8);
  backdrop-filter: blur(10px);
}

.number {
  width: 46px;
  height: 42px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: white;
  color: #012d67;
}

.gradient,
.gradient-number {
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  color: white;
}

.gradient {
  margin-left: 20px;
}

.white {
  background-color: white;
  color: #012d67;
}

.website-development {
  display: flex;
  justify-content: center;
  align-items: center;
  direction: row;
}

/* Media queries for tablets */
/* @media (max-width: 1024px) {
  .our-services {
    display: none;
  }
  .website-development img {
    max-width: 100%;
    display: none;
  }

  .first-group {
    height: auto;
    width: 100%;
    display: none;
  }

  .fact {
    width: 100%;
    font-size: 18px;
    height: 48px;
    display: none;
  }

  .number {
    width: 40px;
    height: 38px;
    display: none;
  }
} */
/* @media (max-width: 768px) {
  .our-services {
    flex-direction: column;
    padding: 10px;
    display: none;
  }

  .website-development img {
    max-width: 100%;
    display: none;
  }

  .first-group {
    height: auto;
    width: 100%;
    display: none;
  }

  .fact {
    width: 100%;
    font-size: 18px;
    height: 48px;
    display: none;
  }

  .number {
    width: 40px;
    height: 38px;
    display: none;
  }
} */

/* Media queries for mobile phones */
/* @media (max-width: 480px) {
  .our-services {
    display: none;
    padding: 10px;
  }

  .website-development {
    display: none;
    flex-direction: column;
    gap: 20px;
  }

  .first-group {
    display: none;
    height: auto;
    width: 100%;
    gap: 15px;
  }

  .fact {
    display: none;
    width: 100%;
    font-size: 16px;
    height: 44px;
  }

  .number {
    display: none;
    width: 36px;
    height: 34px;
  }

  .website-development img {
    max-width: 100%;
    display: none;
  }
} */

/* Package-plan */

.plan-img {
  width: 250px;
  height: 500px;
}

.standard-img {
  width: 230px;
  height: 490px;
  padding-top: 5px;
}

.package-plan {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
}

.plan-detail {
  min-width: 343px;
  margin-bottom: 20px;
}

.plan-detail h3 {
  font-size: 24px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  margin-bottom: 30px;
}

.plan-detail p {
  font-size: 18px;
  font-family: "Glory", sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}

/* Media queries for tablets and mobile */
@media (max-width: 768px) {
  .package-plan {
    flex-direction: column;
    align-items: center;
  }

  .gradient {
    margin-left: 0px;
  }

  .website-development {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    direction: column;
  }

  .carousel .slide img {
    max-height: 500px;
    max-width: 250px;
    margin: 0 auto;
  }
  .first-group {
    height: auto;
    margin: 10px;
  }
  .plan-detail {
    width: 100%;
    text-align: center;
  }
}

.application-journey {
  background-image: url("../../assets/app-journey.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 1024px) {
  .application-journey {
    display: none;
  }
}
