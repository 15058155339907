@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.sitemap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
  align-items: start;
  justify-content: center;
  height: auto;
  min-height: 600px;
}
.image-container {
  min-height: 250px;
}
.image-container img {
  opacity: 0;
  visibility: hidden;
}
.image-container img {
  opacity: 1;
  visibility: visible;
}
@keyframes right-to-left {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(-75%);
  }
}
@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(+100%);
  }
}
.image-container:hover .image-1 {
  opacity: 1;
  visibility: visible;
}
.image-container:hover .image-3 {
  opacity: 1;
  visibility: visible; /* Make the element visible */
  animation: right-to-left 0.5s linear 1 forwards; /* Corrected animation shorthand */
}
.image-container:hover .image-2 {
  opacity: 1;
  visibility: visible; /* Make the element visible */
  transition: 2s ease-in-out;
}

.site-map-card {
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease-in-out, visibility 0s 2s;
}

.sitemap:hover .site-map-card {
  opacity: 1;
  visibility: visible;
  transition: opacity 2s ease-in-out, visibility 0s 0s;
}
@media (max-width: 768px) {
  .site-map-card {
    display: flex;
    max-width: 100px;
  }

  .image-1 {
    opacity: 1;
    visibility: visible;
  }
  .image-3 {
    opacity: 1;
    visibility: visible; /* Make the element visible */
    animation: right-to-left 2s linear 1 forwards; /* Corrected animation shorthand */
  }
  .site-map-card {
    opacity: 1;
    visibility: visible;
    transition: opacity 2s ease-in-out, visibility 0s 0s;
  }
  .image-2 {
    opacity: 1;
    visibility: visible; /* Make the element visible */
    transition: 2s ease-in-out;
  }
  .sitemap {
    align-items: center;
    justify-content: center;
  }
}
.card-container {
  background-image: url("../../assets/app-dev-packages.webp");
  align-items: center;
  display: flex;
  min-height: 791px;
  justify-content: center;
  gap: 32px;
  padding: 20px;
  flex-wrap: wrap;
  background-size: cover;
  background-repeat: no-repeat;
}
.site-map-card {
  width: 100px;
  height: 100px;
}
.ecommerce-card {
  max-width: 375px;
  min-height: 500px;
  padding: 20px;
  color: white;
  border: 2px solid #00bce8;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.ecommerce-card-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

.ecommerce-card-desc {
  font-size: 18px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
}
@media (min-width: 480px) {
  .sitemap {
    display: none;
  }
}
