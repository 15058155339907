@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.approach-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.approach-card {
  height: auto;
  min-width: 300px;
}

.loyalty-dev-num {
  width: 53px;
  height: 53px;
  /* background-color: #00BCE8; */
  background: linear-gradient(313.66deg, #1cd1fc 34.12%, #012d67 92.6%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-family: Outfit;
  font-size: 16.69px;
  font-weight: 600;
  line-height: 16.69px;
  letter-spacing: 0.01em;
  text-align: justified;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.approach-card:hover .feature-card {
  background-image: url("../../assets/paymentSolution/PaymentSolution90deg.webp");
  background-color: rgb(1, 1, 42);
  color: white;
}
.approach-card:hover .feature-card-title {
  color: white;
}
/* .feature-card {
  width: 300px;
  height: 300px;
  border: 3px solid;
  border-image: linear-gradient(to right, skyblue, darkblue) 1;
  box-shadow: none;
} */
.feature-card-title {
  color: black;
  font-weight: bolder;
}
.approach-header {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 20px;
  padding-bottom: 20px;
}

.approach-desc {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
}

.outsourcing-approach {
  margin-bottom: 30px;
}

/* Outsourcing Details */
.outsourcing-detail-container {
  background-image: url("../../assets/WhatOutsourcing.webp");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: 360px;
  color: white;
  font-family: "Glory", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}

.outsourcing-detail-container h3 {
  font-size: 24px;
  font-weight: 600;
  padding: 30px;
  margin: auto;
  font-family: "Playfair Display", serif;
  align-self: flex-start;
}

.outsource-li {
  list-style: circle;
  line-height: 30px;
}

.outsource-ul {
  max-width: 1000px;
  align-self: flex-start;
  padding-left: 50px;
  font-size: 20px;
  margin: auto;
  padding-right: 50px;
}

/* Why with us  */
.why-with-us-container {
  padding: 30px;
}

.why-with-us-container img {
  margin: auto;
  margin-top: 30px;
}

.why-with-us-container h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 20px;
  padding-bottom: 20px;
}

.why-with-us-container p {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
}
