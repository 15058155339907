@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.key-component-desc {
  color: #00bce8;
  font-size: 24px;
  font-family: "Glory", sans-serif;
  font-weight: 600;
  text-align: center;
  padding-top: 20px;
}

.key-component-title {
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.key-components {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.single-key-component {
  width: 267px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  font-family: "Glory", sans-serif;
  font-weight: 500;
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  color: white;
}

.key-component-container {
  padding: 20px;
}

.loyalty-benefit-container {
  background-image: url("../../assets/ourServices/our_service_bg.webp");
  padding: 20px;
  min-height: 496px;
  color: white;

  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.loyalty-card-title {
  font-family: "Playfair Display";
  font-weight: 900;
}
.loyal-dev-btn-ctn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loyalty-dev-num {
  width: 53px;
  height: 53px;
  /* background-color: #00BCE8; */
  background: linear-gradient(313.66deg, #1cd1fc 34.12%, #012d67 92.6%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-family: Outfit;
  font-size: 16.69px;
  font-weight: 600;
  line-height: 16.69px;
  letter-spacing: 0.01em;
  text-align: justified;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.number-fact {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.loyalty-types {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.loyalty-types img {
  width: 400px;
  height: auto;
}

.loyalty-types span {
  font-family: Glory;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.88px;
  text-align: center;
}

.loyalty-app-dev-title {
  font-family: Playfair Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 37.32px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.loyalty-application-dev {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 40px;
}

.title-block {
  width: 265px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Glory;
  font-size: 20px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  color: white;
  margin-top: 10px;
  border-radius: 15px;
}

.single-app-dev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .in {
    margin-left: 40px;
  }
}
