@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.proj-approaches {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.approach-card {
  max-width: 376px;
  height: 189px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding: 20px;
  overflow-y: hidden;
}
.approach-card .feature-card-title {
  font-weight: bold;
}
.approach-card img {
  width: 75px;
  height: 75px;
}

.approach-card p {
  text-align: left;
  font-family: "Glory", sans-serif;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 20px;
  overflow-y: hidden;
}

.approach-card h3 {
  text-align: left;
  font-size: 20px;
  overflow-y: hidden;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  padding-top: 20px;
}

.project-approach-container > h3 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 20px;
  padding-bottom: 30px;
}

/* Benefits */
.benefits-container {
  background-color: #000625;
  color: white;
  padding: 20px;
}

.benefit-card {
  max-width: 300px;
  height: 250px;
  border: 1px solid #00bce8;
  background-color: white;
  color: black;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-y: hidden;
}

.benefit-card-title {
  text-align: left;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 10px;
  padding-bottom: 30px;
  height: auto;
  overflow-y: hidden;
}

.benefit-card-info {
  font-family: "Glory", sans-serif;
  text-align: center;
  font-size: 17px;
  height: auto;
  text-align: justify;
  overflow-y: hidden;
}

.benefit-card img {
  align-self: flex-start;
  padding-bottom: 10px;
}

.benefit-cards-group {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 1200px;
  justify-content: space-evenly;
  margin: auto;
  gap: 16px;
}

.benefits-container h3 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Process */
.process-fact {
  width: 450px;
  height: auto;
  padding: 20px;
  font-size: 12px;
  border: 2px solid;
  border-image: linear-gradient(to right, skyblue, darkblue) 1;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.process-fact h3 {
  font-size: 18px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  margin-bottom: 10px;
}

.process-fact p {
  font-family: "Glory", sans-serif;
  font-size: 18px;
}

.process-fact li {
  list-style-type: circle;
  font-family: "Glory", sans-serif;
  font-size: 18px;
}

.facts {
  display: flex;
  width: 100%;
  max-width: 41vw;
}

.process-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.process-container img {
  width: 400px;
  height: 400px;
}

.process-number {
  margin-top: 20px;
  font-family: "Playfair Display";
  font-weight: bold;
}

/* .prev {
  margin-left: -50px;
} */

.next {
  margin-left: 100px;
}

@media (max-width: 768px) {
  .process-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
  }

  .process-number {
    margin: 10px auto 0px 0px;
    padding: 15px;
    height: auto;
    width: auto;
  }
  .facts {
    align-items: flex-start; /* Align items to the start */
    width: 100%;
    max-width: 95vw;
    flex-direction: column;
  }
  .facts .factss {
    align-items: flex-start;
    width: 100%;
    flex-direction: column-reverse;
  }
  .process-fact {
    max-width: 100%; /* Allow full width */
  }
}
