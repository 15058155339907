@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.management-header,
.benefits-title {
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.selected-key-content {
  background-color: rgb(3, 3, 59);

  border-radius: 15px;
  color: white;
}
.management-container {
  padding: 40px;
}

.management-desc,
.benefits-desc {
  font-size: 18px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
  text-align: center;
}

.management-lists {
  max-width: 1000px;
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  margin: auto;
}

.image-4 {
  opacity: 1;
  visibility: visible;
  height: 150px;
  /* animation: left-to-right 2s linear 1 forwards; */
}
.image-5 {
  opacity: 1;
  visibility: visible; /* Make the element visible */
  /* animation: right-to-left 0.5s linear 1 forwards; */
  height: 300px;
}
.tenant-card {
  margin: 2px;
}
@keyframes right-to-left {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(25px);
  }
}
@keyframes left-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.managment-name {
  width: 267px;
  height: 75px;
  border: 1.8px solid #012d67;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
}

@keyframes ms-background {
  from {
    background-position: -200px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes left-to-right {
  from {
    position: -200px 0;
  }
  to {
    position: 0 0;
  }
}
.image-1 {
  animation: left-to-right 1.5s linear 1 forwards;
}
.function-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.managment-name:hover {
  color: white;
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  animation: ms-background 2s linear 1 forwards;
}

.feature-container {
  background-image: url("../../assets/ourServices/our_service_bg.webp");
  padding: 20px;
  padding-top: 150px;
  min-height: 736px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
}

.feature-header {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: "Playfair Display", serif;
  text-align: center;
  padding-top: 20px;
}

.feature-img {
  width: 600px;
}

.features {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 200px;
}

.feature {
  width: 200px;
  height: 58px;
  border: 1px solid #012d67;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Glory", sans-serif;
  box-shadow: 5px 5px #012d67;
}

.white {
  background-color: white;
  color: #012d67;
}

.gradient {
  background: linear-gradient(102.28deg, #00bce8 -12.02%, #012d67 77.71%);
  color: white;
}

.tenant-card {
  max-width: 360px;
  height: 170px;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.tenant-card:hover p {
  color: white;
}
@media only screen and (max-width: 768px) {
  .feature-container {
    padding-top: 150px;
  }
  .feature-header {
    margin-bottom: 20px;
    font-size: large;
  }
}
.tenant-card:hover {
  background-color: #012049;
  color: white;
  transition: 1s ease-in-out;
  background-image: url("../../assets/paymentSolution/Line&Dot.webp");
  background-position: right;
  background-repeat: no-repeat;
}
.tenant-card-title {
  font-size: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

.tenant-card-desc {
  font-size: 18px;
  font-weight: 400;
  font-family: "Glory", sans-serif;
}

.tenant-cards-container {
  display: flex;
  /* max-width: 1112px; */
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
