@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.app-dev-packages {
  background-image: url("../../assets/app-dev-packages.webp");
  align-items: center;
  display: flex;
  min-height: 791px;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.app-dev-packages h3 {
  color: white;
  text-align: center;
  padding-top: 120px;
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

.app-dev-packages p {
  color: white;
  font-family: "Glory", sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding-top: 30px;
  max-width: 1025px;
  margin: auto;
}

.app-packages {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 30px;
  flex-wrap: nowrap; /* changed from wrap to nowrap */
  width: 100%;
  overflow-x: auto; /* enabled horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* smooth scrolling */
  scroll-snap-type: x mandatory; /*    */
}

.app-packages img {
  width: 280px;
  flex-shrink: 0;
  scroll-snap-align: start;
}

@media (max-width: 1024px) {
  .app-dev-packages {
    background-image: none;
  }

  .app-dev-packages h3,
  .app-dev-packages p {
    color: black;
  }
}

@media (max-width: 768px) {
  .app-dev-packages h3 {
    font-size: 24px;
  }

  .app-dev-packages p {
    font-size: 18px;
  }

  .app-packages img {
    width: 240px;
  }
}

@media (max-width: 480px) {
  .app-dev-packages h3 {
    font-size: 20px;
  }
  .app-packages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .app-dev-packages p {
    font-size: 16px;
  }
}
