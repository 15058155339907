@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.languages-we-use h3 {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  margin: auto;
  text-align: center;
}

.languages-we-use p {
  font-family: "Glory", sans-serif;
  font-size: 20px;
  text-align: center;
}
.languages-icon {
  min-width: 50px;
}
.languages-we-use {
  max-width: 900px;
  align-items: center;
  margin: auto;
  padding: 20px;
}
.languages-icons {
  width: auto;
  min-width: 4500px;
  display: flex;
  flex-direction: row;
}
.languages-icons img {
  height: 50px; /* Responsive height */
  object-fit: cover;
  width: auto;
  margin-top: 20px;
  margin-right: 20px; /* Responsive margin */
}
.languages-icons:hover {
  animation: flowAnimation 20s linear infinite;
  width: 200%; /* Ensure that the container width is enough to accommodate the flow */
}

@keyframes flowAnimation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.icon-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .languages-icons img {
    height: 70px; /* Larger height for tablets and above */
    margin-right: 30px; /* Larger margin for tablets and above */
  }
}

@media (min-width: 1024px) {
  .languages-icons img {
    height: 70px; /* Even larger height for desktops and above */
    margin-right: 35px; /* Even larger margin for desktops and above */
  }
}

.payment-solution {
  background-image: url("../../assets/paymentSolution/OBS\ Pyment\ Solution.webp");
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 610px;
  background-size: cover;
  background-repeat: no-repeat;
}
.payment-solution-image {
  rotate: 0deg;
}
.payment-solution-image:hover {
  animation: payment-solution-image-rotate 2s ease-in-out forwards;
}
.shadow-gradient {
  position: relative;
}
.payment-solution-image-container {
  background-image: url("../../assets/16-9-24/Payment\ Solution\ Bg.webp");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  overflow: visible;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 600px;
  min-height: 590px;
}

@keyframes payment-solution-image-rotate {
  from {
    rotate: 0;
  }
  to {
    rotate: -20deg;
  }
}
@keyframes payment-solution-background-image {
  from {
    background-position: 100px 0;
  }
  to {
    background-position: 0 0;
  }
}
.payment-solution-info h3 {
  color: white;
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.payment-solution-info p {
  color: white;
  margin-bottom: 10px;
}

.read-more {
  color: #00bce8;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .payment-solution {
    flex-direction: column;
    text-align: center;
    background-size: cover; /* Ensure the background image covers the entire container */
    /* background-position: center;  Center the background image */
    height: auto; /* Remove fixed height to allow content to define height */
    padding: 10px;
    justify-content: center;
  }

  .payment-solution img {
    width: auto; /* Adjust width for mobile view */
    max-width: none; /* Remove the max-width limit for smaller screens */
    transform: rotate(5deg); /* Optional: Adjust rotation for mobile view */
    /* max-width: 350px; */
  }

  .payment-solution-info {
    padding-bottom: 50px;
  }
}

@media (max-width: 435px) {
  .payment-solution {
    flex-direction: column;
    text-align: center;
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center;
    height: auto; /* Remove fixed height to allow content to define height */
    padding: 10px;
    justify-content: center;
  }

  .payment-solution img {
    width: auto; /* Adjust width for mobile view */
    max-width: 250px; /* Remove the max-width limit for smaller screens */
  }
}

/* Our Products */
:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-prev::after,
:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-prev,
:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-next,
:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-next::after {
  color: black;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
}

.product-slides {
  max-width: 1300px;
  margin: auto;
  overflow-y: hidden;
}

.ant-card .ant-card-body {
  padding: 0 important;
}

.clinic-img {
  max-width: 418px;
  height: auto;
  background-image: url("../../assets//Group25.webp");
  background-size: 110%;
  background-repeat: no-repeat;
  background-position: center;
}

.product-slide {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
  /* background-color: black; */
}

.product-info {
  max-width: 651px;
  padding: 20px;
}

.headline {
  font-family: "Glory";
  font-weight: bold;
  font-size: 20px;
  color: #00bce8;
}

.product-info h3 {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: 800;
}
.service-header {
  font-family: "Playfair Display", serif;
}
.detail-info {
  font-family: "Glory", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.1;
  text-align: justify;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-carousel
  .slick-dots
  li
  button::after {
  background-color: grey;
  height: 5px;
}

/* :where(.css-dev-only-do-not-override-d2lrxs).ant-carousel .slick-dots li button {
  background-color: black;
} */

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .product-slide {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 25px;
  }

  .clinic-img {
    max-width: 100%;
    height: auto;
  }

  .product-info {
    max-width: 100%;
    padding: 10px;
  }

  .headline {
    font-size: 18px;
  }

  .product-info h3 {
    font-size: 22px;
  }

  .detail-info {
    font-size: 18px;
    text-align: justify;
  }
}

@media (max-width: 480px) {
  .headline {
    font-size: 16px;
  }

  .product-info h3 {
    font-size: 20px;
  }

  .detail-info {
    font-size: 16px;
  }
}

/* Our Services */
.our-services {
  background-image: url("../../assets/ourServices/our_service_bg.webp");
  background-size: cover;
  padding: 20px;
  min-height: 736px;
  display: flex;
  flex-direction: column;
}

.lighting-header {
  display: block;
  max-width: 100%;
}

.our-services h3 {
  color: white;
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  margin-top: -100px;
}

:where(.css-dev-only-do-not-override-d2lrxs).ant-card .ant-card-body {
  padding: 0 !important;
}

.card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 250px;
  border-radius: 7px;
  height: 300px;
}

.card-title {
  font-family: "Glory", sans-serif;
  writing-mode: vertical-rl; /* Makes the text vertical */
  text-orientation: mixed; /* Proper orientation for vertical text */
  padding: 20px 5px; /* Space around the text */
  background-color: #00adef; /* Background color for the sidebar */
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  rotate: 180deg;
  height: 100%;
}

.card-image {
  height: 100%;
  width: auto; /* Make image cover the remaining space */
  object-fit: cover; /* Ensures the image doesn't stretch */
  height: auto;
  height: 300px;
  flex-grow: 1;
}

.service-card-group {
  display: flex;
  gap: 20px;
  padding-bottom: 80px;
  transform: translateY(-30px);
}

/* Tablet and Mobile Responsiveness */
@media (max-width: 1024px) {
  .our-services {
    background-color: #000625;
  }

  .service-card-group {
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    overflow: hidden;
  }

  .ant-card {
    width: 158px !important;
    /* margin-bottom: 20px; */
    height: 315px;
  }
}

@media (max-width: 768px) {
  .our-services {
    padding: 10px;
    background-color: #000625;
    background-image: none;
    min-height: fit-content;
  }

  .lighting-header {
    padding-top: 0;
    margin: auto;
    margin-top: 0;
  }

  .service-card-group {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    overflow: hidden;
  }

  .ant-card {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .our-services h3 {
    font-size: 20px;
  }
}

/* Hero header */
/* Base Styles */
.hero-section-header {
  background-image: url("../../assets/hero-section-header.webp");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  min-height: 400px;
  color: white;
  font-family: "Glory", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.hero-section-header p {
  font-size: 32px;
  font-weight: 400;
}

.hero-section-main {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 36px;
  margin: 0;
}

.hero-section-main > span {
  color: #00bce8;
}

.explore-more-btn {
  margin-top: 60px;
  width: 186px;
  height: 50px;
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  background-color: #000625;
  color: white;
  cursor: pointer;
  transition: 0.3s, color 0.3s;
  box-shadow: 25px;
  background: linear-gradient(102.28deg, #00bce8, #012d67);
  border-radius: 5px;
}

.explore-more-btn:hover {
  color: white;
  border: none;
  border-radius: 5px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hero-section-header {
    padding-left: 10px;
    padding-right: 10px;
    min-height: auto;
    background-size: cover;
    padding-bottom: 70px;
  }

  .hero-section-header p {
    font-size: 24px;
  }

  .hero-section-main {
    font-size: 28px;
  }

  .explore-more-btn {
    width: 160px;
    height: 45px;
    font-size: 16px;
    margin-top: 40px;
    justify-self: start;
  }
}

@media (max-width: 480px) {
  .hero-section-header {
    padding-bottom: 70px;
  }

  .hero-section-header p {
    font-size: 20px;
  }

  .hero-section-main {
    font-size: 24px;
  }

  .explore-more-btn {
    width: 140px;
    height: 40px;
    font-size: 14px;
    margin-top: 30px;
  }
}

/* Features */
.feature-header-container {
  margin: auto;
  max-width: 856px;
}

.feature-title,
.feature-info {
  font-family: "Glory", sans-serif;
  font-size: 20px;
}
.about-card-title {
  font-family: "Playfair Display";
}

.feature-header-container h3 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 24px;
}

.feature-card-title {
  padding-bottom: 10px;
  font-family: "Glory", sans-serif;
  font-size: 20px;
  color: #00bce8;
  font-weight: 600;
}

.feature-card-info {
  font-family: "Glory", sans-serif;
  font-size: 18px;
  text-align: justify;
}

.feature-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  max-height: 250px;
  padding: 20px;
  border-radius: 10%;
  overflow-y: hidden;
  margin-bottom: 15px;
}

.feature-card-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

/* 6 years exp */
.six-year-exp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0px 20px;
  margin-bottom: 20px;
}

.six-year-exp-info {
  width: 610px;
}

.six-year-exp-info h3 {
  font-family: "Playfair Display";
  font-weight: bolder;
  font-size: 24px;
}

.divider {
  width: 160px;
  border: 2px solid black;
  margin-top: 10px;
  margin-bottom: 10px;
}

.six-year-exp-info p {
  font-family: "Glory";
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .six-year-exp-info {
    width: 100%;
    text-align: center;
    text-align: left;
    justify-content: start;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .six-year-exp-container {
    flex-direction: column;
    align-items: center;
  }

  .six-year-exp-container img {
    width: 100%;
    max-width: 300px; /* Adjust this value as needed */
    height: auto;
    margin-bottom: 20px;
  }

  .six-year-exp-info h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .divider {
    width: 100px;
    display: none;
  }

  .explore-more-btn {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .six-year-exp-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .divider {
    width: 80px;
    display: none;
  }

  .six-year-exp-info p {
    font-size: 14px;
  }

  .explore-more-btn {
    font-size: 14px;
  }
}

.ant-carousel .slick-prev {
  width: 11px !important;
  height: 27px !important;
}

.ant-carousel .slick-next {
  width: 30px !important;
  height: 27px !important;
}
