/* General Styles */
header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  padding: 0 16px;
  background-color: white;
}

/* .ant-menu-title-content:hover {
  border: 2px solid;
  border-image: linear-;
} */

/* Logo Styles */
header img {
  width: 125px;
  height: 53px;
}

/* Menu Styles */
.menu-horizontal {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

/* Drawer Button Styles */
.drawer-button {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu-horizontal {
    display: none;
  }

  .drawer-button {
    display: block;
    border: none;
    right: 0;
  }

  header img {
    width: 100px;
    height: 40px;
  }
}

@media (min-width: 769px) {
  .menu-horizontal {
    display: flex;
  }
  .footer-group {
    display: flex;
    flex-direction: row;
  }
  .drawer-button {
    display: none;
  }
}

/* Footer */
.logo-social-media-group img {
  width: 150px;
  height: 66px;
  margin-top: 20px;
}

.logo-social-media-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.footer-group {
  color: white;
  background-color: #000625;
  min-height: 250px;
  width: 100%;
  padding-top: 40px;
}

.services,
.solutions,
.products,
.contact h4 {
  margin-bottom: 20px;
  font-family: "Outfit";
}

.solutions {
  width: 224px;
}
.contact {
  width: 250px;
}
.services,
.products,
.logo-social-media-group {
  width: 224px;
}
.address {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
  .social-media-group .fa-icon {
    margin-right: 10px;
    font-size: 18px;
  }
  .footer-group {
    align-items: center;
  }
}
