.digital-trans {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  padding-top: 20px;
  padding-bottom: 30px;
}
.feature-card-title {
  font-family: "Playfair Display";
  margin-bottom: 10px;
}
.feature-card-info {
  overflow-y: hidden;
}
.feature-card {
  overflow: hidden;
  height: 255px;
  justify-content: start;
  box-shadow: 0px;
  border-radius: 5%;
}
.solution-card {
  height: 260px;
  overflow-y: hidden;
}
.feature-card-short {
  padding-top: 20px;
  overflow: hidden;
  height: 200px;
  box-shadow: 0px;
  padding: 10px;
}
.solution-card-short {
  height: 210px;
  overflow-y: hidden;
}
