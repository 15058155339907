@import url("https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Glory:ital,wght@0,100..800;1,100..800&family=Outfit:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Mono:ital,wght@0,500;1,500&display=swap");

.cloud-hosting-fact {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  overflow-y: hidden;
}
.hexagon {
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  padding-bottom: 15px;
}
.cloud-hosting-fact p {
  font-family: "Glory", sans-serif;
  font-size: 24px;
  font-weight: 500;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cloud-hosting-container img {
  width: 400px;
  height: 400px;
}

.cloud-hosting-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.cloud-hosting-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.loyalty-dev-num {
  width: 53px;
  height: 53px;
  /* background-color: #00BCE8; */
  background: linear-gradient(313.66deg, #1cd1fc 34.12%, #012d67 92.6%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-family: Outfit;
  font-size: 16.69px;
  margin: 8px;
  font-weight: 600;
  line-height: 16.69px;
  letter-spacing: 0.01em;
  text-align: justified;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .cloud-hosting-container {
    gap: 20px;
  }

  .cloud-hosting-container img {
    width: 300px;
    height: 300px;
  }

  .cloud-hosting-fact p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .cloud-hosting-fact-rr {
    flex-direction: row-reverse;
  }
  .cloud-hosting-container {
    flex-direction: column;
    align-items: center;
  }

  .cloud-hosting-fact {
    display: flex;
    justify-content: start;
    align-items: center;
    max-height: 60px;
    overflow-y: hidden;
  }
  .cloud-hosting-container img {
    width: 250px;
    height: 250px;
  }

  .cloud-hosting-fact p {
    font-size: 18px;
  }
  .cloud-hosting-container-rr {
    flex-direction: column;
    align-items: center;
  }

  .cloud-hosting-container-rr img {
    width: 250px;
    height: 250px;
  }

  .cloud-hosting-fact-rr p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .cloud-hosting-container img {
    width: 200px;
    height: 200px;
  }

  .website-development {
    flex-direction: column;
  }
  .fact1 {
    flex-direction: row-reverse;
  }
  .cloud-hosting-fact p {
    font-size: 16px;
  }
  .cloud-hosting-fact {
    max-height: none;
    margin-bottom: 20px;
  }
}
